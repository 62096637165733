import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import styled from 'styled-components';

import SectionHeader from '../components/SectionHeader';
import Anchorlink from '../components/Anchorlink';
import Content from '../components/Content';

const BlogSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px;
`;

const PostContainer = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  max-width: 90vw;
  img {
    width: 100%;
  }
`;

const TagsTitle = styled.h4`
  font-size: ${({ theme }) => theme.font.size.s};
  margin: 0 0 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.five};
  position: relative;
`;

const TagsContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 40px;
`;

const TagsList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  margin-right: 15px;
`;

const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <BlogSection>
      <div>{<PostContent /> || ''}</div>
      {helmet || ''}
      <PostContainer>
        <SectionHeader title={title} subtitle={description} />
        <PostContent content={content} />
        {tags && tags.length ? (
          <TagsContainer>
            <TagsTitle>Tags</TagsTitle>
            <TagsList>
              {tags.map((tag) => (
                <ListItem key={`${tag}tag`}>
                  <Anchorlink
                    ariaLabel="tag anchor"
                    to={`/tags/${kebabCase(tag)}/`}
                  >
                    {tag}
                  </Anchorlink>
                </ListItem>
              ))}
            </TagsList>
          </TagsContainer>
        ) : null}
      </PostContainer>
    </BlogSection>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  helmet: PropTypes.node.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BlogPostTemplate;
